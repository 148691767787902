import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Select from 'react-select';
import axios from 'axios';
import { FaTrash } from 'react-icons/fa/';
import 'rc-checkbox/assets/index.css';
import { CenteredH3 } from '../Styled/Styled';
import { roleOptions } from '../Auth/roles';
import swal from 'sweetalert';

// Bovenste box "User information"
const UserInfoContainer = styled.div`
  background-color: #fff;
  padding: 25px;
  border: 1px solid #aaa;
  border-radius: 10px;
`;

// Onderste box "Link user to company"
const DashboardLinkContainer = styled.div`
  position: relative;
  top: 20px;
  background-color: #fff;
  border-radius: 10px;
  padding-top: 20px;
  border: 1px solid #ababab;
  @media (max-width: 382px) {
    top: 20px;
  }
`;
const DashboardContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  text-align: center;
  @media (max-width: 382px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

// Grid with input fields
const GridContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  padding-top: 50px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  padding: 20px;
  @media (max-width: 382px) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
`;
const RowContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 50px;
  padding: 20px;
`;

const ButtonContainer = styled.div`
  position: relative;
  top: 25px;
`;

// TRASHBIN ICON
const IconWrapSpan = styled.span`
  color: #c9302c;
  position: relative;
  width: 10%;
  margin: 0 auto;
  top: 30px;
  /* right: -40px;  */
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 382px) {
    grid-column: 3/3;
    margin-top: 26px;
  }
`;
const StyledInput = styled.input`
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const SmallMargin = styled.label`
  @media (max-width: 382px) {
    //margin-bottom: 32px;
    //display: inline-block;
  }
`;

const LinkBox = styled.div`
  margin-bottom: 1rem;
  @media (max-width: 382px) {
    grid-column: 1/3;
    margin-top: 20px;
  }
`;
const DashboardSelect = props => {
  const selection =
    props.selection && props.selection.value
      ? props.options.find(option => option.value === props.selection.value)
      : null;
  return (
    <DashboardContainer>
      <LinkBox className="form-group">
        <label htmlFor="surveyID">Linked to project:</label>
        <Select
          isClearable
          defaultValue={props.edit ? selection : 'Select'}
          value={selection}
          onChange={selectedOption =>
            props.addDash(selectedOption, props.index)
          }
          options={props.options}
        />
      </LinkBox>

      <IconWrapSpan
        onClick={option =>
          props.deleteDashboard(option, props.index, props.selection)
        }
      >
        <FaTrash size={30} />
      </IconWrapSpan>
    </DashboardContainer>
  );
};

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfDashboards: 0,
      companies: [],
      projects: [],
      userCompanies: [],
      //invalidEmail:false
    };
    this.addSelect = this.addSelect.bind(this);
    this.submit = this.submit.bind(this);
    this.deleteDashboard = this.deleteDashboard.bind(this);
    this.selectRole = this.selectRole.bind(this);
  }

  async componentDidMount() {
    try {
      const { user } = this.props;
      let permissions;
      if (user['https://dvj-portal.com/permissions']) {
        permissions = user['https://dvj-portal.com/permissions'].permissions;
      } else if (user.app_metadata) {
        permissions = user.app_metadata.permissions;
      }
      const token = this.props.auth.getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      const companies = axios.get('/api/companies', config);
      const projects = axios.get('/api/projects/project-list', config);

      const [companyResult, projectsResult] = await Promise.all([
        companies,
        projects
      ]);

      if (permissions && this.props.adminWindow && this.props.edit) {
        this.setState({
          numberOfDashboards: permissions.projects.length,
          companies: companyResult.data.companies,
          projects: projectsResult.data
        });
      } else {
        this.setState({
          numberOfDashboards: 1,
          companies: companyResult.data.companies,
          projects: projectsResult.data
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({ error });
    }
    // this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    try {
      if (
        prevProps.user.nickname !== this.props.user.nickname &&
        this.props.edit
      ) {
        const permissions = this.props.user[
          'https://dvj-portal.com/permissions'
        ]
          ? this.props.user['https://dvj-portal.com/permissions'].permissions
          : this.props.user.app_metadata.permisisons;
        this.setState({ numberOfDashboards: permissions.projects.length });
      } else if (
        prevProps.user.nickname !== this.props.user.nickname &&
        !this.props.edit
      ) {
        this.setState({ numberOfDashboards: 1 });
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleCompany = options => {
    let companies = options;
    if (!Array.isArray(options)) {
      companies = [options];
    }
    this.props.handleCompanies(companies);
  };

  submit(e) {
    e.preventDefault();

    if (this.props.role.value){
      if (this.props.role.value !== 'client'){
        const emailInput = this.props.email
        const splitEmail = emailInput.split('@')
        if (splitEmail[1] !== 'dvjresearchgroup.com' && splitEmail[1] !== 'dvj-insights.com' ){
          return swal("Cannot proceed with submission!",
           "For Admin, Topadmin, Consultant only @dvjresearchgroup.com and @dvj-insights.com are allowed. "+
           "Please select a different user permissions role from the dropdown to continue")
        }
      }
    }

    if (this.props.edit) {
      this.props.editUser();
    } else {
      this.props.addUser();
    }
  }

  selectRole(options) {
    const role = {
      role: {
        value: options.value,
        label: options.label,
        admin: options.admin,
        topAdmin: options.topAdmin,
        internal: options.internal
      }
    };
    this.props.changeRole(role);
    if (!options.internal) {
      this.setState({ userCompanies: this.state.userCompanies.slice(0, 1) });
    }
  }

  addSelect(e) {
    e.preventDefault();
    let { numberOfDashboards } = this.state;
    numberOfDashboards++;
    this.setState(prevState => ({
      numberOfDashboards
    }));
    this.props.addDashboardRow();
  }

  deleteDashboard(e, i) {
    let { numberOfDashboards } = this.state;
    numberOfDashboards--;
    this.setState({
      numberOfDashboards
    });
    this.props.removeDashboard(i);
  }

  render() {
    let visibleDashboards = null;
    if (
      this.props.user.app_metadata &&
      this.props.user.app_metadata.permissions &&
      this.props.adminWindow &&
      !this.props.edit
    ) {
      const dashboards = this.state.projects.map((val, index) => (
        <DashboardSelect
          key={val.value + index}
          index={index}
          options={this.state.projects}
          selection={this.props.selectedDashboards[index]}
          addDash={this.props.addUserDashboard}
          deleteDashboard={this.deleteDashboard}
          edit={this.props.edit}
        />
      ));
      visibleDashboards = dashboards.slice(0, this.state.numberOfDashboards);
    } else if (
      this.props.user.app_metadata &&
      this.props.user.app_metadata.permissions &&
      this.props.adminWindow &&
      this.props.edit
    ) {
      visibleDashboards = this.props.selectedDashboards.map((val, index) => (
        <DashboardSelect
          key={val.value + index}
          index={index}
          options={this.state.projects}
          selection={this.props.selectedDashboards[index]}
          addDash={this.props.addUserDashboard}
          deleteDashboard={this.deleteDashboard}
          edit={this.props.edit}
        />
      ));
    }
    let userCompanies;
    if (this.props.userCompanies.length < 1) {
      if (
        this.props.role &&
        this.props.role.hasOwnProperty('internal') &&
        this.props.role.internal
      ) {
        userCompanies = this.props.userCompanies;
      } else if (
        this.props.role &&
        this.props.role.hasOwnProperty('internal') &&
        !this.props.role.internal
      ) {
        userCompanies = this.props.userCompanies[0];
      } else {
        userCompanies = { value: '', label: 'Select...' };
      }
    } else {
      userCompanies = this.props.userCompanies;
    }
    return (
      <React.Fragment>
        {this.props.success ? (
          <div className="alert alert-success">{this.props.message}</div>
        ) : null}
        {this.props.error ? (
          <div className="alert alert-danger">{this.props.message}</div>
        ) : null}
        <form className="form" onSubmit={this.submit}>
          <UserInfoContainer>
            <CenteredH3>User Information: </CenteredH3>
            <GridContainer>
              <div className="form-group">
                <label htmlFor="first-name">Enter first name:</label>
                <StyledInput
                  type="text"
                  name="firstName"
                  id="first-name"
                  value={this.props.firstName}
                  onChange={this.props.handleChange}
                  disabled={this.props.type === 'edit'}
                  required
                />
              </div>
              <div className="form-group">
                <SmallMargin htmlFor="last-name">Last name:</SmallMargin>
                <StyledInput
                  name="lastName"
                  id="lastName"
                  type="text"
                  value={this.props.lastName}
                  onChange={this.props.handleChange}
                  disabled={this.props.type === 'edit'}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email-address">Email address:</label>
                <StyledInput
                  name="email"
                  id="email"
                  type="text"
                  value={this.props.email}
                  onChange={this.props.handleChange}
                  required
                />
                 {/* {this.state.invalidEmail ? <i className='textStyle'>Cannot proceed with submission! 
                 For Admin, Topadmin, Consultant only @dvjresearchgroup.com and @dvj-insights.com are allowed.
                 Please select a different user permissions role from the dropdown to continue </i> : null } */}
              </div>
              {this.props.adminWindow ? (
                <div className="form-group">
                  <label htmlFor="company">Select company(ies):</label>
                  <Select
                    isMulti={this.props.role && this.props.role.internal}
                    value={userCompanies}
                    options={this.state.companies}
                    onChange={this.handleCompany}
                    required
                  />
                </div>
              ) : null}
              {this.type === 'edit' ? (
                <div className="form-group">
                  <div>
                    <label htmlFor="password">Enter password:</label>
                    <StyledInput
                      name="password"
                      id="password"
                      type="password"
                      value={this.props.password}
                      onChange={this.props.handleChange}
                      required={this.props.type !== 'edit'}
                    />
                  </div>
                  <div>
                    <label htmlFor="passwordCheck">Re-enter password:</label>
                    <StyledInput
                      name="passwordCheck"
                      id="passwordCheck"
                      value={this.props.passwordCheck}
                      onChange={this.props.handleChange}
                      type="password"
                      required={this.props.type !== 'edit'}
                    />
                  </div>
                </div>
              ) : null}
              {this.props.adminWindow ? (
                <>
                  <div>
                    <label htmlFor="role">Select user permissions role:</label>
                    <Select
                      name="role"
                      value={this.props.role}
                      options={roleOptions}
                      onChange={selectedOption =>
                        this.selectRole(selectedOption)
                      }
                    />
                    <i className='textStyle'>*users with roles Consultant,Admin,Topadmin should have '@dvjresearchgroup.com' OR 
                    '@dvj-insights.com' as their Email domains </i>
                  </div>
                  <div>
                    <label htmlFor="employeeRole">
                      Define employee role: (e.g. Researcher)
                    </label>
                    <StyledInput
                      name="employeeRole"
                      id="employeeRole"
                      value={this.props.employeeRole}
                      onChange={this.props.handleChange}
                      required
                    />
                  </div>
                </>
              ) : null}
              <div className="checkbox-container">
                <ButtonContainer>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </ButtonContainer>
              </div>
            </GridContainer>
          </UserInfoContainer>
          {this.props.adminWindow ? (
            <DashboardLinkContainer>
              <CenteredH3>Link user to project: </CenteredH3>
              <RowContainer>
                <div style={{ margin: '20px auto', textAlign: 'center' }}>
                  <button
                    style={{ margin: '0 auto' }}
                    className="btn btn-success"
                    onClick={this.addSelect}
                  >
                    Add a project
                  </button>
                </div>
                {visibleDashboards}
              </RowContainer>
            </DashboardLinkContainer>
          ) : null}
        </form>
      </React.Fragment>
    );
  }
}
AddUser.propTypes = {
  dashboards: PropTypes.array
};
AddUser.defaultProps = {
  dashboards: []
};
export default AddUser;
