// import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { dvjRed, dvjBlue } from './theme';

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: dvjRed
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: dvjBlue
    },
    background: {
      default: 'transparent'
    }
  },
  shape: {
    borderRadius: 0
  }
});
export default muiTheme;
