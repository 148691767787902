import React from 'react';
import { withRouter } from 'react-router';
import { Loading } from '../helpers/Loading';

function Callback(props) {
  props.props.auth
    .handleAuthentication()
    .then(() => {
      const returnTo =
        props.props.auth.getReturnTo() === '/callback' ||
        props.props.auth.getReturnTo() === '/login'
          ? '/'
          : props.props.auth.getReturnTo();
      props.props.history.push(returnTo);
    })
    .catch(error => {
      console.log(error);
    });

  return <Loading />;
}

export default withRouter(Callback);
