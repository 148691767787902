import { tempHEMAprojects } from './tempHEMAprojects';

export const ROLE_NONE = 0;
export const ROLE_CLIENT = 1;
export const ROLE_HEMA_CLIENT = 2;
export const ROLE_CONSULTANT = 10;
export const ROLE_ADMIN = 20;
export const ROLE_TOPADMIN = 99;

export const roleOptions = [
  // ROLE_CLIENT:
  {
    value: 'client',
    label: 'Client',
    admin: false,
    topAdmin: false,
    internal: false
  },
  // ROLE_CONSULTANT:
  {
    value: 'user',
    label: 'Consultant',
    admin: false,
    topAdmin: false,
    internal: true
  },
  // ROLE_ADMIN:
  {
    value: 'admin',
    label: 'Admin',
    admin: true,
    topAdmin: false,
    internal: true
  },
  // ROLE_TOPADMIN:
  {
    value: '*',
    label: 'TopAdmin',
    admin: true,
    topAdmin: true,
    internal: true
  }
];
/**
 *
 * @param {import('./Auth').default} auth
 */
export function getUserRole(auth) {
  if (!auth.isAuthenticated()) return ROLE_NONE;
  const userProfile = auth.getProfile();
  if (!userProfile) return ROLE_NONE;
  const permissionsRole =
    userProfile['https://dvj-portal.com/permissions'].permissions.role;
  if (permissionsRole.topAdmin) return ROLE_TOPADMIN;
  if (permissionsRole.admin) return ROLE_ADMIN;
  if (permissionsRole.value === 'user') return ROLE_CONSULTANT;
  if (permissionsRole.hemaUser) return ROLE_HEMA_CLIENT;
  return ROLE_CLIENT;
}

/**
 *
 * @param {import('./Auth').default} auth
 */
export function getAllowedProjects(auth) {
  if (!auth.isAuthenticated()) return [];
  const userProfile = auth.getProfile();
  if (!userProfile) return [];
  const userRole = getUserRole(auth);

  if (userRole === ROLE_TOPADMIN) return ['*'];

  let { projects } = userProfile[
    'https://dvj-portal.com/permissions'
  ].permissions;

  if (userRole === ROLE_HEMA_CLIENT) {
    projects = projects.concat(tempHEMAprojects);
  }
  return projects;
}
