import 'react-app-polyfill/ie11'; // For IE 11 support
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'react-app-polyfill/ie11';
import { unregister } from './registerServiceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import Auth from './Auth/Auth';
import ErrorBoundary from './ErrorBoundary';
import dotenv from 'dotenv';

dotenv.config();
const auth = new Auth();
ReactDOM.render(
  <Router>
    <ErrorBoundary>
      <App auth={auth} />
    </ErrorBoundary>
  </Router>,
  document.getElementById('root')
);
unregister();
