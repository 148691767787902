import React from 'react';
import { NavLink } from 'react-router-dom';

export default function NotFound() {
  return (
    <div style={{ margin: '20px auto', textAlign: 'center' }}>
      You do not have access to view this page.
      <br />
      <NavLink to="/">Return to the homepage</NavLink>
    </div>
  );
}
