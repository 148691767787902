import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { FaCaretDown, FaCaretRight, FaDownload } from 'react-icons/fa';
import Grow from '@material-ui/core/Grow';
import theme from './Styled/theme';
import Button from './components/buttons/Button';
import {
  getUserRole,
  ROLE_ADMIN,
  ROLE_TOPADMIN,
  ROLE_CONSULTANT
} from './Auth/roles';

const StyledDownload = styled.a`
  font-size: 20px;
`;
const SidePanel = styled.div`
  background-color: #222d32;
  width: ${theme.sidebarWidth}px;
  z-index: 100;
  padding: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  opacity: 1;
  height: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (max-width: 960px) {
    padding-left: 0px;
    padding-right: 0px;
    box-shadow: none;
    width: 100%;
    height: 300px;
  }
`;

const MainContent = styled.div``;
const ButtonContainer = styled.div``;

const StyledLink = styled(NavLink)`
  color: #fff;
  display: inline-block;
  width: 100%;
  font-weight: 400;
  text-decoration: none;
  padding: 5px;
  width: 90%;
  border-radius: 4px;
  padding-left: 5px;
  cursor: pointer;
  &:focus,
  &:hover,
  &:visited,
  &:active {
    text-decoration: none;
    color: #fff;
    background-color: #586e77;
  }
`;

const StyledSub = styled(StyledLink)`
  margin-left: 15px;
  font-size: 13px;
`;

const StyledDivLink = styled.div`
  color: #fff;
  display: inline-block;
  width: 100%;
  font-weight: 400;
  text-decoration: none;
  padding: 5px;
  width: 90%;
  border-radius: 4px;
  padding-left: 5px;
  cursor: pointer;
  &:focus,
  &:hover,
  &:visited,
  &:active {
    text-decoration: none;
    color: #fff;
    background-color: #586e77;
  }
`;
const HelpModal = styled.div`
  position: fixed;
  top: 0;
  left: 250px;
  width: 500px;
  height: 100%;
  overflow-x: hidden;
  padding: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  opacity: 1;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.9);
`;
const UserGrid = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-gap: 10px;
  color: #fff;
  padding-bottom: 30px;
  position: relative;
`;

const UserImg = styled.img`
  width: 50px;
  border-radius: 50%;
`;

const UserDetails = styled.p`
  align-self: center;
  vertical-align: middle;
  margin: 0;
  line-height: 1.5;
  font-size: 14px;
  & small {
    font-size: 10px;
    color: rgba(255, 255, 255, 0.7);
  }
`;

const ButtonContainer2 = styled.div`
  position: relative;
  bottom: 0px;
  left: 80px;
  @media (max-width: 960px) {
    padding-left: 0px;
    padding-right: 0px;
    box-shadow: none;
    position: relative;
    left: initial;
  }
`;
const SideBar = props => {
  const { companies, user, auth, history } = props;
  const { permissions } = user['https://dvj-portal.com/permissions'];

  const links = [];
  const mounted = true;
  const userRole = getUserRole(auth);
  if (
    userRole === ROLE_TOPADMIN ||
    userRole === ROLE_ADMIN ||
    userRole === ROLE_CONSULTANT
  ) {
    links.push({
      url: '/admin',
      title: 'Admin',
      sub: [
        { url: '/admin', title: 'Users' },
        { url: '/admin/add-user', title: 'Add user' },
        { url: '/admin/add-project', title: 'Add project' },
        { url: '/admin/project-list', title: 'Project list' },
        { url: '/admin/company-list', title: 'Company List' }
      ]
    });
    if (permissions.role.topAdmin) {
      const csv = {
        url: '/admin/uploadCSV',
        title: 'Upload CSV'
      };
      links[0].sub.push(csv);
    }
  }
  links.push({ url: '/projects', title: 'Projects' });
  companies.forEach(company => {
    const tempCompany = {
      url: `/company/${company.name}`,
      title: `${company.name} files`,
      companyName: company.name,
      sub: company.menuItems.map(menuItem => {
        return {
          url: `/company/${company.name}/${menuItem.url}`,
          title: menuItem.name,
          id: menuItem._id
        };
      }),
      limiter: true
    };
    const userItem = {
      url: `/company/${company.name}/user-list`,
      title: 'users'
    };
    tempCompany.sub.unshift(userItem);
    links.push(tempCompany);
  });

  return (
    <>
      <SidePanel>
        <MainContent>
          {user ? (
            <UserGrid>
              <UserImg src={user.picture} />
              <UserDetails>
                <strong>{user.name}</strong>
                <br />
                <small>
                  {user['https://dvj-portal.com/user_metadata'].company}
                </small>
              </UserDetails>
            </UserGrid>
          ) : null}

          {links.map((link, i) => {
            const urlCheck = link.limiter ? 2 : 1;
            return (
              <Grow
                direction="up"
                in={mounted}
                key={link.title}
                {...(mounted ? { timeout: i * 300 } : {})}
              >
                <React.Fragment>
                  {history.location.pathname.split('/')[urlCheck] ===
                  link.url.split('/')[urlCheck] ? (
                    <React.Fragment>
                      {link.sub && (
                        <StyledLink
                          exact
                          key={`${link.url}${link.title}`}
                          to={link.url}
                          activeStyle={{ fontWeight: '700' }}
                        >
                          {link.title}
                        </StyledLink>
                      )}
                      {!link.sub && (
                        <StyledLink
                          exact
                          key={`${link.url}${link.title}`}
                          to={link.url}
                          activeStyle={{ fontWeight: '700' }}
                        >
                          {link.title}
                        </StyledLink>
                      )}
                      {link.sub &&
                        link.sub.map((sub, j) => {
                          return (
                            <Grow
                              direction="up"
                              in={mounted}
                              key={sub.title}
                              {...(mounted ? { timeout: j * 300 } : {})}
                            >
                              <StyledSub
                                exact
                                key={sub.title}
                                to={{
                                  pathname: sub.url,
                                  menuName: sub.title,
                                  menuID: sub.id,
                                  companyName: link.companyName
                                }}
                                activeStyle={{ fontWeight: '700' }}
                              >
                                {sub.title}
                              </StyledSub>
                            </Grow>
                          );
                        })}
                    </React.Fragment>
                  ) : (
                    <StyledLink
                      exact
                      key={`${link.url}${link.title}`}
                      to={link.url}
                      activeStyle={{ fontWeight: '700' }}
                    >
                      {link.sub ? (
                        history.location.pathname.includes(link.url) ? (
                          <FaCaretDown />
                        ) : (
                          <FaCaretRight />
                        )
                      ) : null}
                      {link.title}
                    </StyledLink>
                  )}
                </React.Fragment>
              </Grow>
            );
          })}
          <StyledDivLink
            onClick={e => {
              props.showHelp(e, props);
            }}
          >
            Help
          </StyledDivLink>
        </MainContent>
        {!props.loggedOut ? (
          <ButtonContainer>
            <Button onClick={props.signOut}>Logout</Button>
          </ButtonContainer>
        ) : null}
      </SidePanel>
      {props.showHelpDiv ? (
        <HelpModal>
          <h3 style={{ color: '#5e9af9' }}>{props.helpHeader}</h3>
          {props.helpText.split(/\n+/g).map(val => {
            if (val.trim().length > 0) {
              return <p key={val}>{val}</p>;
            }
            return false;
          })}
          <ButtonContainer2>
            <StyledDownload
              href="https://dvj-insights.com/resources/dvj-portal-manual.pdf"
              target="_blank"
            >
              Download full manual&nbsp; &nbsp;
              <FaDownload
                style={{ textAlign: 'center', margin: '0 auto', color: '' }}
              />
            </StyledDownload>
          </ButtonContainer2>
        </HelpModal>
      ) : null}
    </>
  );
};
export default SideBar;
