import React, { Component } from 'react';
import {
  MuiThemeProvider
  // , StylesProvider
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import './App.css';
import { Route, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Loadable from 'react-loadable';
import axios from 'axios';
import Callback from './Callback/Callback';
import ErrorBoundary from './ErrorBoundary';
import UserProfile from './Client/UserProfile';
import SecuredRoute from './Auth/SecuredRoute';
import Denied from './Denied';
import SideBar from './SideBar';
import { Loading } from './helpers/Loading';
import { helpText } from './helpers/helpText';
import theme from './Styled/theme';
import muiTheme from './Styled/muiTheme';

const Context = React.createContext();

const Home = Loadable({
  loader: () => import('./Home' /* webpackChunkName: "Home" */),
  loading: Loading
});
const Admin = Loadable({
  loader: () => import('./Admin/Admin' /* webpackChunkName: "Admin" */),
  loading: Loading
});
const StoryApp = Loadable({
  loader: () =>
    import(
      './Client/Stor-e-telling/StoryApp' /* webpackChunkName: "StoryApp" */
    ),
  loading: Loading
});
const Projects = Loadable({
  loader: () => import('./Projects' /* webpackChunkName: "Projects" */),
  loading: Loading
});
const TableApp = Loadable({
  loader: () =>
    import('./Client/Report/TableApp' /* webpackChunkName: "TableApp" */),
  loading: Loading
});
const PresentationApp = Loadable({
  loader: () =>
    import(
      './Client/Presentation/PresentationApp' /* webpackChunkName: "PresentationApp" */
    ),
  loading: Loading
});
const StoryOverview = Loadable({
  loader: () =>
    import(
      './Client/Stor-e-telling/StoryOverview' /* webpackChunkName: "StoryOverview" */
    ),
  loading: Loading
});
const ClientView = Loadable({
  loader: () =>
    import('./Client/ClientView' /* webpackChunkName: "ClientView" */),
  loading: Loading
});
const AssociationApp = Loadable({
  loader: () =>
    import(
      './Client/Associations/AssociationApp' /* webpackChunkName: "AssociationApp" */
    ),
  loading: Loading
});
const ClientProjects = Loadable({
  loader: () =>
    import('./Client/ClientProjects' /* webpackChunkName: "ClientProjects" */),
  loading: Loading
});
const CompanyInfo = Loadable({
  loader: () =>
    import(
      './Client/ViewFiles/CompanyInfo' /* webpackChunkName: "CompanyInfo" */
    ),
  loading: Loading
});
const UserOverview = Loadable({
  loader: () =>
    import(
      './Client/ViewFiles/UserOverview' /* webpackChunkName: "UserOverview" */
    ),
  loading: Loading
});
const ViewFiles = Loadable({
  loader: () =>
    import('./Client/ViewFiles/ViewFiles' /* webpackChunkName: "ViewFiles" */),
  loading: Loading
});
const ChangePassword = Loadable({
  loader: () =>
    import('./helpers/ChangePassword' /* webpackChunkName: "ChangePassword" */),
  loading: Loading
});
const ContentBody = styled.div`
  width: calc(100% - ${theme.sidebarWidth}px);
  height: 100%;
  margin-left: ${theme.sidebarWidth}px;
  @media (max-width: 960px) {
    margin-left: 0px;
    margin-top: 300px;
    width: 100%;
  }
`;

const StyledApp = styled.div`
  height: 100%;
  display: flex;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
class App extends Component {
  constructor() {
    super();
    this.state = {
      showHelpDiv: false,
      helpHeader: 'Projects',
      profile: {},
      loaded: false,
      companies: [],
      loadedMenu: false,
      helpText:
        'Upon logging in, you will see the Projects page. This is a list with all your (company’s) projects. If you have a lot of projects, you can use the search bar to filter them down by project name or ID. The title of the project is clickable and leads to the general project page, but you can also click directly on one of the components available in the project. Those are the buttons on the bottom of each project card. Most of the time, at least ‘Report table’ will be available.'
    };
    this.handleClick = this.handleClick.bind(this);
    this.showHelp = this.showHelp.bind(this);
    this.getCompanies = this.getCompanies.bind(this);
  }

  async componentDidMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
  }

  async componentDidUpdate(prevProps, prevState) {
    try {
      if (
        !this.state.loaded &&
        prevProps.auth.access_token &&
        this.state.companies.length === prevState.companies.length
      ) {
        await this.getCompanies();
      }
    } catch (error) {
      this.setState(() => {
        throw Error(`Problem with loading the application: ${error}`);
      });
    }
  }

  async getCompanies() {
    try {
      const token = this.props.auth.access_token;
      const result = await axios.get('/api/company/menu-links', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      this.setState({
        companies: result.data,
        loadedMenu: true,
        loaded: true
      });
    } catch (error) {
      this.setState({}, () => {
        throw error.response.data;
      });
    }
  }

  showHelp(e, props) {
    const component = helpText[document.title.replace(' ', '').toLowerCase()];
    if (component) {
      this.setState({
        showHelpDiv: !this.state.showHelpDiv,
        helpHeader: component.header,
        helpText: component.body
      });
    }
  }

  handleClick(e) {
    e.preventDefault();
    this.props.auth.signOut();
  }

  render() {
    if (
      this.props.history.location.pathname !== '/login' &&
      this.props.history.location.pathname !== '/callback'
    ) {
      this.props.auth.setReturnTo(this.props.history.location.pathname);
    }
    if (this.props.history.location.pathname === '/changepassword') {
      return (
        <Route
          path="/changepassword"
          exact
          render={properties => (
            <ChangePassword {...properties} history={this.props.history} />
          )}
        />
      );
    }
    if (
      this.props.history.location.pathname !== '/' &&
      this.props.history.location.pathname !== '/callback' &&
      !this.props.auth.isAuthenticated()
    ) {
      this.props.auth.login();
      return <div />;
    }

    const props = {
      authenticated: this.props.auth.isAuthenticated(),
      token: this.props.auth.getToken(),
      user: this.props.auth.getProfile(),
      auth: this.props.auth,
      history: this.props.history
    };

    const routes = [
      { path: '/', component: Home, admin: false, exact: true },
      { path: '/admin', component: Admin, admin: true, exact: false },
      { path: '/projects', component: Projects, admin: false, exact: true },
      { path: '/home', component: ClientProjects, admin: false, exact: false },
      {
        path: '/projects/:company/:surveyID',
        component: ClientView,
        admin: false,
        exact: true
      },
      {
        path: '/company/:company',
        component: CompanyInfo,
        admin: false,
        exact: true
      },
      {
        path: '/company/:company/user-list',
        component: UserOverview,
        admin: false,
        exact: true
      },
      {
        path: '/company/:company/:menuItem',
        component: ViewFiles,
        admin: false,
        exact: true
      },
      {
        path: '/projects/:company/:surveyID/stor-e-telling',
        component: StoryOverview,
        admin: false,
        exact: true
      },
      {
        path: '/projects/:company/:surveyID/stor-e-telling/storybook-maker',
        component: StoryApp,
        admin: false,
        exact: true
      },
      {
        path: '/projects/:company/:surveyID/associations',
        component: AssociationApp,
        admin: false,
        exact: true
      },
      {
        path: '/projects/:company/:surveyID/report-table',
        component: TableApp,
        admin: false,
        exact: true
      },
      {
        path: '/projects/:company/:surveyID/presentation',
        component: PresentationApp,
        admin: false,
        exact: true
      },
      {
        path: '/projects/:company/:surveyID/documents',
        component: PresentationApp,
        admin: false,
        exact: true
      },
      {
        path: '/user/:username',
        component: UserProfile,
        admin: false,
        exact: true
      },
      {
        path: '/changepassword',
        component: ChangePassword,
        admin: false,
        exact: true
      },
      { path: '/access-denied', component: Denied, admin: false, exact: true },

      { component: Denied }
    ];
    return (
      <Context.Provider value={{ reRenderApp: this.getCompanies }}>
        {/* <StylesProvider injectFirst> */}
        <MuiThemeProvider theme={muiTheme}>
          <CssBaseline />
          <ErrorBoundary user={props.user}>
            <StyledApp>
              {props.authenticated ? (
                <SideBar
                  history={props.history}
                  auth={props.auth}
                  user={props.user}
                  signOut={this.handleClick}
                  token={props.token}
                  showHelp={this.showHelp}
                  showHelpDiv={this.state.showHelpDiv}
                  helpText={this.state.helpText}
                  helpHeader={this.state.helpHeader}
                  companies={this.state.companies}
                />
              ) : null}
              <ContentBody className="content">
                <SecuredRoute
                  routes={routes}
                  componentProps={props}
                  loadedMenu={this.state.loadedMenu}
                />
                <Route
                  path="/callback"
                  exact
                  render={properties => (
                    <Callback {...properties} props={props} />
                  )}
                />
              </ContentBody>
            </StyledApp>
          </ErrorBoundary>
        </MuiThemeProvider>
        {/* </StylesProvider> */}
      </Context.Provider>
    );
  }
}

export const { Consumer } = Context;
export default withRouter(App);
