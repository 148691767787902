import React, { Component } from 'react';
import axios from 'axios';
import { ResponsiveContainer } from '../Styled/Styled';
import AddUser from '../Admin/AddUser';

export default class UserProfile extends Component {
  constructor() {
    super();
    this.state = {
      user: {},
      firstName: '',
      lastName: '',
      company: '',
      email: '',
      password: '',
      passwordCheck: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.editUser = this.editUser.bind(this);
  }

  componentDidMount() {
    const { authenticated } = this.props;
    if (authenticated) {
      const token = this.props.auth.getToken();
      const user = this.props.user.sub;
      axios
        .get(`/api/users/${user}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(result => {
          const user = result.data;
          const { email } = user;
          const user_meta = user.user_metadata;
          const { company } = user_meta;
          const { firstName } = user_meta;
          const { lastName } = user_meta;
          this.setState({ email, company, firstName, lastName });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  handleChange(e) {
    const change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  }

  editUser(e) {
    try {
      const pass = this.state.password;
      const { check } = this.state;
      if (pass) {
        if (pass !== check && pass.length < 6) {
          this.setState({
            error: true,
            message: 'Passwords do not match or are below 6 characters'
          });
        }
      }
      const token = this.props.auth.getToken();
      const user = this.props.user.sub;
      const body = {
        password: pass,
        email: this.state.email,
        user_metadata: {
          company: this.state.company
        },
        user,
        userprofile: true
      };
      axios
        .patch(`/api/users/${user}`, body, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(result => {
          this.setState({
            success: true,
            message: 'Successfully updated account',
            password: '',
            passwordCheck: ''
          });
        })
        .catch(error => {
          this.setState({
            error: true,
            message: error.response.data.message,
            password: '',
            passwordCheck: ''
          });
        });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    // const firstName= this.s.user.name.split(' ')[0];
    // const lastName = this.props.user.name.split(' ')[1]
    console.log(this.props.user);
    return (
      <React.Fragment>
        <ResponsiveContainer>
          <h1>Welcome {this.props.user.name}</h1>
          <AddUser
            user={this.props.user}
            adminWindow={false}
            email={this.state.email}
            company={this.state.company}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            handleChange={this.handleChange}
            addUser={this.editUser}
            error={this.state.error}
            success={this.state.success}
            message={this.state.message}
            password={this.state.password}
            passwordCheck={this.state.passwordCheck}
            type="edit"
          />
        </ResponsiveContainer>
      </React.Fragment>
    );
  }
}
