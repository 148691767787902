import React, { Component } from 'react';
import Rollbar from 'rollbar';
import { NavLink } from 'react-router-dom';
import { ContainerDiv } from './Styled/Styled';

export default class ErrorBoundary extends Component {
  constructor() {
    super();
    this.state = {
      hasError: false,
      err: '',
      uuid: ''
    };
  }

  componentDidCatch(err, info) {
    this.setState({
      hasError: true,
      message: err.message,
      uuid: 'not enabled for development'
    });

    if (process.env.REACT_APP_REACTENV === 'production') {
      const rollbar = new Rollbar({
        accessToken: '518dfe860c0f463696218f6d4d4ab88c',
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment:
            process.env.REACT_APP_REACTENV === 'dev'
              ? 'development'
              : 'production',
          person: {
            id: this.props.user.sub,
            email: this.props.user.email,
            username: this.props.user.name
          }
        }
      });

      rollbar.error(err.stack, (err, result) => {
        if (err) rollbar.error(err);
        this.setState({ uuid: result.result.uuid });
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <ContainerDiv style={{ display: 'block' }}>
          <div
            className="alert alert-danger"
            style={{
              margin: '0 auto',
              width: '100%',
              marginTop: '50px'
            }}
          >
            <strong>
              {' '}
              Oh no, something went wrong! Error: {this.state.message}{' '}
            </strong>
            <br />
            <p style={{ color: '#000' }}>
              Please email support at support@dvjresearchgroup.com and copy
              paste the error code below in your email.
            </p>
          </div>
          <br />
          <div
            style={{
              backgroundColor: '#fff',
              borderRadius: '5px',
              padding: '10px',
              textAlign: 'center'
            }}
          >
            <p>{this.state.uuid}</p>
          </div>
          <NavLink to="/">Return to the homepage</NavLink>
        </ContainerDiv>
      );
    }
    return this.props.children;
  }
}
