export const helpText = {
  projects: {
    header: 'Projects',
    body: `Upon logging in, you will see the Projects page. This is a collection of all your project cards, with each card representing a project. The projects are sorted newest first. If you have a lot of projects, you can use the search bar to filter them down by project name or ID. The title of the project is clickable and leads to the general project page, but you can also click directly on one of the components available in the project. Those are the buttons on the bottom of each project card. Most of the time, at least ‘Table report’ will be available. `
  },
  projectoverview: {
    header: 'Project: Overview',
    body: `In this project overview you will find the most important project information, such as the sample size, date and a description of the project. There’s a little more detail here than on the ‘Projects’ page. Finally, there are buttons that lead to the available components (depending on the type of project – not every project has storytelling for instance).`
  },
  storytellingoverview: {
    header: 'Overview (storytelling)',
    body: `Storytelling always starts with a cue. We have a short explanation which we present to the respondents. Then we ask them to write a story, inspired by that cue. Sometimes there’s multiple cues available. Therefore, on the left-hand side you can select the cue for which you want to see the results.

    Once you click a cue, the bar chart in the right column (‘Story evaluation’) will show the quantitative results. This is how the respondents rated their own story; in other words, they decided themselves whether the story is positive, relevant, or different. Because of the quantitative nature, you can compare them to our benchmark numbers. The benchmark is the median score for all stories over all projects. The top 20% are the best 20% projects. In other words, if your project’s numbers are equal or higher than the top 20% norm, it means it’s doing better than 80% of all projects. You should always strive to beat the benchmark, but depending on the type of cue you might realistically expect some metrics to be lower. For instance, a low involvement product with a lot of competitors is going to have a hard time scoring high on ‘Different from others’ and ‘A story I would like to share’.
    
    On the bottom right there’s a button to ‘Go to storybook maker’. This is where most of the fun starts!`
  },
  storytellingtool: {
    header: 'Storybook maker',
    body: `This is a very powerful tool that enables you to view, select and export the stories that are relevant to you. Let’s go over it from top to bottom.

    If the questionnaire for your project was in any other language than English, there will be a button in the red top bar to translate the stories. Clicking this button, ‘Story language’, enables you to choose if you want to see the stories in their original (non-English) language or in English. The translation in English is automatically done by Google Translate, which yields very good results according to our tests. Nevertheless, occasionally there might be a few strings that can’t be translated correctly (for instance grossly miss-spelled words or brand names). For all intents and purposes however, we deem the results very good and absolutely usable.
    
    Moving on to the second horizontal bar, there’s an option to select the cue. Like on the Story overview page, you get to decide for what cue you want to see the stories. The dropdown contains a shortened version of the cue – the full cue shows up below in the large white box. This is the exact cue that’s presented to the respondents to trigger their stories.
    
    After you selected the cue, you can either download our default storybook (click ‘Generate story book’) or customize it yourself. The default storybook is really great and often all that you would need. It contains all stories (for that selected cue), categorized by Positive, Neutral and Negative stories. You only need to supply the title and subtitle and in return you get a PDF that you can directly download. So if you got an important meeting where you need to discuss the stories, you could in the very last minute download this default book and print it out for all to marvel at.
    
    Of course, some cases require some additional work. We also offer functionality to customize your story book. Below the story cue, you will already see one column with stories. You have three ways to modify that column:
    1. Adding filters. One filter is automatically attached. If you hover over the input that says ‘Filter…’ you will be shown on what metric this filter operates (for instance ‘gender’). You can click the filter element to see the options that are available in that filter and click them to activate (for instance: ‘Female’). By default, no option is selected, and the filter is not affecting the stories.
    
    You can remove the filter by clicking on the X on the upper right of the element. You can remove the selected option within the filter by clicking on the light gray X inside the filter element.
    
    Adding a filter is a matter of clicking the plus icon. You then choose the metric on which you want to filter. After selecting, you have to click the new filter component to select the option within the metric to use.
    
    You can technically add up to 10 filters, but we recommend sticking with just one or two in order to see the most stories.
    2. Searching stories. There’s a search bar that searches all your stories. Try typing in your brand name and see all stories that specifically mention your brand!
    3. Sorting. Often when working with storytelling, you want to build a narrative to best present the research outcomes. In that case, picking a few more articulate stories that spark the imagination is what’s needed. We found that generally, the longer stories are also more articulate and better at really telling a story. To enable to pick those out easily, we built in the option to sort by story length.
    
    That’s not all though. What’s often very welcome, is to compare stories across two different segments. For instance, men versus women, or positive stories versus negative stories. You can do that by adding a second column (click the plus with the text ‘Add second column to compare’). That second column works exactly the same as the first one. In fact, you could even make identical selections but in almost all cases you would choose different filters.
    
    So on to building the storybook. You got all the right filters and selections in place in one or two columns, now it’s time to cherry pick. You can browse through the pages per column to view more stories. Click any story that you want to include in your storybook. Once you are happy with your selection, click on the big red button at the bottom that says ‘Export stories’.`
  },
  storybooksettings: {
    header: 'Storybook settings',
    body: `On this page, you fill in the final details for your own, 100% customized, storybook. The first two fields are the title and subtitle, both go on the cover. The second two fields are the introduction title and the introduction. The latter can be up to two (small) paragraphs. There’s not much room in the storybook so keep it short to be sure all text is included.

    After filling the general information in, you can scroll down to fill in the category title and introduction (shown before the stories) per column of stories. Here you can explain which selections you made to end up with these stories. For instance: “The following are stories that were marked as ‘positive’ by their authors”. You will see a preview of the column(s) with the stories. For reference, you can also download the contents of each column if you wish by clicking ‘download stories’ on top of the column. This is just a simple .csv file you can open in Excel. Most of the time however, you just want the full storybook.
    
    Before proceeding, make sure you filled in everything. Otherwise you’ll end up with empty spaces in your storybook. Then, after reviewing, click on ‘Download storybook (.pdf)’. The storybook is dynamically built at that time, so it might take a few seconds – up to 60 seconds. In our testing however, we found that it usually took around 2-10 seconds, depending on the number of stories and the processing power of the computer used.
    
    Made a mistake or want to change something? Don’t worry, your stories are still selected when you go back. You can just add or remove a couple stories and come back. Make sure to check the titles and introductions again and you’re good to go.`
  },
  tablereport: {
    header: 'Table report',
    body: `Where the storytelling shows qualitative data with a few quantitative measures, the report table is very much quantitative. You have access to all data from the questionnaire here (after cleaning, of course). Let’s go over it from top to bottom.

    On the top, there’s a bright green button that says ‘Download all tables’. This is a table export straight from our survey software. The data is cleaned and weighted, but other than that there’s not selections or styling, it is very much a rough table report for those in need of as much raw data as possible.
    
    In most cases however, you want to look at more specific results. First you’ll see the Metrics selection. Here you can select any question that has been asked in the survey. You can also select them all if you want everything, but it will slow the page down a little bit. We recommend selecting a handful of metrics to start with. Every metrics/question will get its own table below.
    
    You can also enable various splits. From the dropdown you can see the available splits and select them. You can choose one, or you can select multiple if you want to see them next to each other in the same table. By default, ‘total’ is selected (which is not really a split), but usually you are able to split on gender, age group or anything else that has been discussed upfront.
    
    After the splits, you’ll see all the tables, one for each metric/question that you’ve selected. The question label is displayed in the header, along with a download button. Clicking that buttons downloads a .csv file with the data and splits you see in that table. 
    
    You can open the .csv-file in any spreadsheet application (Excel, most likely). Depending on the regional settings, it might not automatically layout the data in different cells. In that case you would select the first column (which contains all data in that scenario), and use the Text-to-columns function (found in the Data tab). Click ‘delimited’ on the first tab, and ‘Choose ‘comma’ as separator on the second (deselect the rest). In the small preview window you will see the values correctly aligned in cells. Click ‘Next’, there you can change the data format per column if needed. Generally you can just click ‘Finish’ here and all should be good.
    
    The table itself shows the split titles in the top row. The row below that shows the categories within that split. The data starts on the next row, with the combined category (Total). In this row, you will see the N of respondents within each column.
    
    After that, you will see each row starts with one of the answering options and it’s average percentage values per category.
    
    That’s all there is on the tables. It enables you to quickly run a table for a specific question, split it on a relevant variable and look at the results and download them. Basically, there’s little filtering between the respondents and your data – we just make sure it’s high quality, properly cleaned and weighted, and you can do the rest yourself (even if you’re in a different time zone or stick to weird office hours!).`
  },
  presentation: {
    header: 'Presentation',
    body: `Finally, there’s the ‘Presentation’ module. This is where you’ll find your traditional report (if available) and any supporting data/files that you might need for this project. It shows the type of file and the title, so you quickly know which files you need to download. You can do so simply by clicking on it.`
  },
  importproject: {
    header: 'Importing a project',
    body: `Importing a project consists of a few stages. First of there is the project information box. All the information filled in here will be visible for the client, so make sure to be as complete as possible with the project details. The survey ID is the same survey ID as in Decipher
    
    Secondly there are the general project options. You will first have to select the type op survey (currently only default is available but this might change). Then select the components for this project (Storytelling, associations, report table and/or presentateion). Selecting one of these components can trigger additional fields which you will have to fill in. Finally, there is the language of the project. If the selected language is anything other than English, the stories and/or associations will be translated to English.
    If you either need storytelling or associations, please read 1 or more of the following sections: 

    Storytelling settings: 
    If you have selected storytelling, an additional box will appear with some fields that have to be completed. The first 'tab' consists of storytelling 'settings' (Cues and metrics). If you press the red button under cues a row with fields will appear. The first first dropdown menu will contain the cues from your survey. Please write the full corresponding storycue in the field titled 'Enter story cue'. Finally select whether the cue is just text, or will have an image as well. If you want additional story cues, press the red button again. Or if you'd like to remove one, press the gray thrashcan icon.
    You can also add 5 additional storymarkers. By pressing the red button below 'Story markers' a new row will appear. The first dropdown menu will ask you to decide between whether the storymarker should be a Top2 or a custom marker. In the case of a top2, the system will automatically scale a 5-point question to a 3-point question. In the case of a custom marker you have to define the values yourself.
    In any case you will need to add the Decipher label as it is labeled in your survey (e.g. QSMSTORY2r1) and add a dashboard label (this is what the client will see, so make sure to label it correctly as well). If you are done with filling out the story cues and story markers, press the 'Import stories' button and you can begin cleaning your stories.

    Storytelling cleaning: 
    If you have imported the stories the story cleaning tab will be available. Click on the 'Story cleaning' tab to begin. Here you will see all of your stories. You can select the amount of stories displayed per page, and have certain story sorting options on the right. 
    If you are done cleaning, or want to take a break, click the red 'save stories' button on the bottom of the page. This will save your stories to the database, and you can continue at a later time. Please note that your stories will not have been translated yet!
    When you are sure all your stories are cleaned and you want to finalize the importing of this component (and also translate the stories), please check the box left of the submit button and then click the submit button. This will start the translating process. Beware that this might take some time for larger surveys.

    Association settings:
    If you have selected the association component, an additional box will appear (or the box will have an additional tab in case you also selected storytelling). This box will have a number of fields based upon your survey. The first column displays the question as it was asked in your survey (this is mainly there to assist you in remembering what that label was about).
    There are a minimum of 3 fields you will have to fill in. The first field contains the association 'type' name. There are a number of predefined types in the dropdown, but if your type is not listed you can type in the type of association to create it and then select it. The second field are the category/brand names: here you want to fill in the different categories / brands. So lets say your association type was 'Brand', you will have to fill in the different brands in this field.
    Once you have selected your type of survey and the values of this type, you will have to fill in the values of the association metric. As shown in the first column the question of that metric is displayed, and you just need to fill in the values (e.g. positive, neutral, negative). NOTE: order is important!
    Once you are done filling out the association settings, please press the import associations button and your associations will be imported.
    Association cleaning: 
    If you have imported the stories the association cleaning tab will be available. Click on the 'association cleaning' tab to begin. Here you will see all of your associations. You can select the amount of stories displayed per page. The associations will always be sorted alphabetically.
    If you are done cleaning, or want to take a break, click the red 'save associations' button on the bottom of the page. This will save your associations to the database, and you can continue at a later time. Please note that your associations will not have been translated yet!
    When you are sure all your stories are cleaned and you want to finalize the importing of this component (and also translate the associations), please check the box left of the submit button and then click the submit button. This will start the translating process. Beware that this might take some time for larger surveys.
    `
  },
  admin: {
    header: 'Admin panel',
    body: `Welcome to the admin panel of the DVJ portal. 
    Here you can add or edit users and add or edit projects. Upon coming to this page you will see a list of existent users. You can click on a user to edit this user (and also add additional projects to their profile)
    Click on the desired menu item to navigate there.`
  }
};
