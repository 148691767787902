import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import {
  FaChevronCircleRight,
  FaPlusSquare,
  FaTimesCircle
} from 'react-icons/fa/';
import Select from 'react-select';
import Button from '../components/buttons/Button';
import { dvjRed } from './theme';

// CENTRALE BOX
export const ResponsiveContainer = styled.div`
  padding: 20px;
  margin: 0 auto;
  max-width: 1270px;
  transition: 0.2s linear;
  /* @media (min-width: 961px) {
    margin-left: 300px;
  } */
`;

export const StoryInput = styled.input`
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #ddd;
`;

export const StoryIntroInput = styled.textarea`
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #ddd;
`;

export const IconWrapSpan = styled.span`
  color: #ccc;
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

export const IconWrapSpanUp = styled.span`
  color: #ccc;
  position: absolute;
  top: 40px;
  right: 20px;
  color: ${dvjRed};
  cursor: pointer;
  font-size: 20px;
  z-index: 99;
`;

export const IconWrapSpanMenuItems = styled.span`
  color: #ccc;
  position: absolute;
  top: 26px;
  right: 20px;
  color: ${dvjRed};
  cursor: pointer;
  font-size: 20px;
  z-index: 99;
`;

export const StyledLink = styled(NavLink)`
  color: #fff;
  display: inline-block;
  width: 100%;
  font-weight: 700;
  text-decoration: none;
  padding: 5px;
  width: 90%;
  border-radius: 4px;
  padding-left: 5px;
  cursor: pointer;
  &:focus,
  &:hover,
  &:visited,
  &:active {
    text-decoration: none;
    color: #fff;
    background-color: #586e77;
  }
`;
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
  @media (max-width: 1367px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1280px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    display: block;
  }
`;
export const GridContainerTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;
export const DownloadButton = styled.button`
  /* display: inline-block; */
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
  width: 75%;
  margin: 0 auto;
  /* margin-right: 25px; */
  &:hover {
    background-color: #338e33;
    border-color: #398c39;
  }
  grid-column: 2/2;
  margin-top: 30px;
`;
export const StyledHeader4 = styled.h4`
  text-align: center;
  margin-top: 25px;
`;
export const ContainerDivFour = styled.div`
  display: grid;
  max-width: 1270px;
  margin: 0 auto;
  display: grid;
  padding: 10px 20px 20px 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  @media (max-width: 1367px) {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 10px 20px 20px 20px;
  }
  @media (max-width: 768px) {
    display: block;
    padding: 10px 20px 20px 20px;
  }
`;
export const ContainerDivThree = styled.div`
  display: grid;
  max-width: 1270px;
  margin: 0 auto;
  position: relative;
  padding: 10px 20px 20px 20px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
`;
export const GridContainerNoPadding = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  padding-top: 50px;
  position: relative;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  @media (max-width: 1367px) {
    padding: 10px 20px 20px 20px;
  }
  @media (max-width: 768px) {
    display: block;
    padding: 10px 20px 20px 20px;
  }
`;
export const ContainerDivTwo = styled.div`
  display: grid;
  max-width: 1270px;
  margin: 0 auto;
  display: grid;
  position: relative;
  padding: 10px 20px 20px 20px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  @media (max-width: 1367px) {
    padding: 10px 20px 20px 20px;
  }
  @media (max-width: 768px) {
    display: block;
    padding: 10px 20px 20px 20px;
  }
`;
export const ContainerDiv = styled.div`
  max-width: 1270px;
  background-color: #f0ece8;
  margin: 0 auto;
  display: grid;
  grid-column: 2 / 3;
  min-height: 100px;
  padding: 20px 20px 20px 20px;
  // grid-template-columns: 1fr 1fr;
  position: relative;
  grid-gap: 10px;
  /*   grid-template-columns: 1fr 2fr;
 */
  @media (max-width: 1380px) {
    display: block;
    min-height: 250px;
  }
  @media (max-width: 768px) {
    display: block;
    padding: 10px 20px 20px 20px;
  }
`;

export const ProjectInfoContainer = styled.div`
  max-width: 1270px;
  margin: 0 auto;
  padding: 10px 20px 10px 20px;
  background-color: #fdfffc;
  border-radius: 5px;
  border: 1px solid #aaa;
  margin-bottom: 40px;
  @media (max-width: 382px) {
    margin-bottom: 20px;
    padding: 10px 10px 10px 10px;
  }
`;

export const BlockDiv = styled.div`
  transition: all 0.2s linear;
  background: ${props =>
    props.selected ? props.theme.darkbg : props.theme.bg};
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;
  border: '1px solid #ddd';
  box-shadow: '0px 0px 3px 1px rgba(100,100,100,0.1)';
  &:hover {
    background-color: #fff6f5;
  }
  min-height: 90px;
`;

export const StyledButton = styled(Button)`
  && {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    height: 50px;
    display: block;
    grid-column: span 2;
  }
`;

export const ProjectContainer = styled.div`
  /* height: 400px; */
  width: 100%;
  border: 2px solid white;
  border-radius: 0px;
  box-sizing: border-box;
  position: relative;
  padding: 20px;
  box-shadow: #e1e1e7 0px 1px 1px 1px;
  background-color: #fff;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;
export const ProjectsView = styled.div`
  padding: 40px;
  transition: 0.2s linear;
  @media (max-width: 960px) {
    padding: 20px;
    margin-left: 0px;
  }
`;
export const StyledRightCircle = styled(FaChevronCircleRight)`
  float: right;
  transition: 0.2s ease-in-out;
  position: absolute;
  margin-top: 3px;
  right: 18px;
  float: right;
  color: ${dvjRed};
  font-size: 22px;
`;

export const ShortcutContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px;
  margin: 0 auto;
  width: 100%;
  padding: 0px;
  margin-top: 20px;
  float: right;
  @media (max-width: 768px) {
    float: none;
  }
`;
export const StyledIconLink = styled(NavLink)`
  height: 100px;
  width: 100%;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  color: #777c83;
  padding-top: 5px;
  border: 1px solid #eee;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  border-radius: 5px;
  border: 2px solid #fff;
  box-sizing: border-box;
  &:hover {
    border: 2px solid #c9302c;
    color: ${dvjRed};
    text-decoration: none;
    box-shadow: #e1e1e7 0px 1px 1px 1px;
  }
`;
export const StyledParagraph = styled.p`
  text-align: center;
  font-weight: 700;
`;

export const BoldLabel = styled.label`
  font-weight: bold;
`;
export const StyledListItem = styled.div`
  font-size: 14px;
  color: #666;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;
export const StyledSelect = styled(Select)`
  width: 25%;
  margin: 20px 0px 20px 0px;
  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
  }
`;

export const StoryListDiv = styled.div`
  background: #fff;
  padding: 10px 10px 0px;
  border-radius: 3px;
  box-shadow: 0px 0px 3px 3px rgba(200, 200, 200, 0.1);
  overflow-y: scroll;
  position: relative;
`;

export const StyledBar = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 5px;
  width: 100%;
  /* min-height: 100px; */
`;
export const ButtonContainer = styled.div`
  cursor: pointer;
  position: relative;
  text-align: center;
  bottom: 0;
  color: #fff;
  border-radius: 5px;
  height: 40px;
  padding: 2px 8px 6px 8px;
`;
export const PopUpDiv = styled.div`
  background-color: #fff;
  position: absolute;
  top: 38px;
  left: 0;
  color: #666;
  width: 200%;
  z-index: 1;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 3px 0px rgba(120, 100, 100, 0.4);
  border-radius: 3px;
  padding: 10px;
`;
export const CueImg = styled.img`
  width: 100px;
  height: auto;
  float: inline-start;
  margin-right: 20px;
  margin-bottom: 15px;
`;

export const Cue = styled.div`
  // grid-column: span 2 / auto;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 3px 3px rgba(100, 100, 100, 0.3);
  text-align: center;
  font-size: 150%;
  margin: 20px 0px 20px;
  color: #555;
`;

export const ModalButtonContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  text-align: center;
`;
export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 101;
`;
export const FaPlusStyled = styled(FaPlusSquare)`
  font-size: 18px;
  position: absolute;
  top: 10%;
  left: 10%;
  color: #666;
`;
export const FaCloseStyled = styled(FaTimesCircle)`
  font-size: 25px;
`;
export const FaCloseStyledSmall = styled(FaTimesCircle)`
  font-size: 16px;
  position: absolute;
  right: -5px;
  bottom: 25px;
  color: #666;
  z-index: 99;
  cursor: pointer;
`;
export const FilterContainer = styled.div`
  position: relative;
  width: 90%;
`;
export const CenteredDiv = styled.div`
  position: fixed;
  background-color: #fff;
  width: 500px;
  height: 150px;
  left: 50%;
  top: 50%;
  border-radius: 10px;
  margin-left: -250px;
  margin-top: -75px;
  /* box-shadow: 
   */
  padding: 10px;
  @media (max-width: 382px) {
    width: 20%;
  }
`;
export const SelectContainer = styled.div`
  position: relative;
  @media (max-width: 768px) {
    display: block;
  }
`;
export const TableContainer = styled.div`
  max-width: 1270px;
  margin: 0 auto;
  display: grid;
  padding: 20px 0 20px 0px;
  grid-template-columns: 1fr;
  grid-gap: 10px;
`;
export const SingleTable = styled.div`
  margin: 25px 0px;
  max-width: 100%;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
`;

export const CenteredH3 = styled.h3`
  text-align: center;
  font-weight: bold;
  font-family: Montserrat;
  margin-top: 16px;
`;

export const StyledH1 = styled.h1`
  font-weight: bold;
  font-family: Montserrat;
`;

export const TitleH1 = styled.h1`
  font-weight: bold;
  font-family: Montserrat;
  max-width: 1270px;
  @media (max-width: 382px) {
    padding-bottom: 10px;
  }
`;
