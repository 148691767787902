import auth0 from 'auth0-js';
// import history from "../history";
import axios from 'axios';

/**
 * Class for handling the authentication of the user (using Auth0)
 */
export default class Auth {
  constructor() {
    let url = 'http://localhost:3000/callback';
    let logoutUrl = 'http://localhost:3000/';
    if (process.env.REACT_APP_REACTENV === 'production') {
      url = 'https://portal.dvj-insights.com/callback';
      logoutUrl = 'https://portal.dvj-insights.com/';
    } else if (process.env.REACT_APP_REACTENV === 'staging') {
      url = 'https://stage.portal.dvj-insights.com/callback';
      logoutUrl = 'https://stage.portal.dvj-insights.com/';
    }
    this.state = {
      url,
      logoutUrl
    };
    this.auth0 = new auth0.WebAuth({
      domain: 'dvj-portal.eu.auth0.com',
      audience: 'https://dvj-portal.com/api/',
      clientID: 'x8OOWrGvY0PpCGN1IzO6hmq9PWUSVg0G',
      redirectUri: url,
      responseType: 'token id_token',
      scope: 'openid profile email '
    });
    this.getProfile = this.getProfile.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.setSession = this.setSession.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  getProfile() {
    return this.profile;
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.setSession(authResult);
        resolve();
      });
    });
  }

  isAuthenticated() {
    if (this.expiresAt) {
      return new Date().getTime() < this.expiresAt;
    }
    return false;
  }

  login() {
    this.auth0.authorize();
  }

  signOut() {
    axios
      .get('/logout', {
        headers: { Authorization: `Bearer ${this.access_token}` }
      })
      .then(result => {
        this.idToken = null;
        this.profile = null;
        this.expiresAt = null;
        this.auth0.logout({
          returnTo: this.state.logoutUrl,
          clientID: 'x8OOWrGvY0PpCGN1IzO6hmq9PWUSVg0G'
        });
        this.props.history.push('/');
      })
      .catch(error => {
        console.log(error);
      });
  }

  // s
  getToken() {
    return this.access_token;
  }

  logout() {
    // clear id token and expiration
    this.idToken = null;
    this.expiresAt = null;
  }

  setReturnTo(url) {
    localStorage.setItem('returnTo', url);
  }

  getReturnTo() {
    return localStorage.getItem('returnTo');
  }

  async checkCurrentSession() {
    this.auth0.checkSession({}, (err, result) => {
      if (err) return err;
      this.setSession(result);
      return result;
    });
  }

  setSession(authResult) {
    this.access_token = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.profile = authResult.idTokenPayload;
    // set the time that the id token will expire at
    this.expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
  }
}
