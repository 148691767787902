import React from 'react';
import PropTypes from 'prop-types';
import MuiButton from '@material-ui/core/Button';
import styled from 'styled-components';
import { dvjBlue } from '../../Styled/theme';

const StyledButton = styled(MuiButton)`
  &&:hover {
    background-color: ${dvjBlue};
  }
`;

const Button = ({ children, ...props }) => {
  return (
    <StyledButton variant="contained" color="primary" {...props}>
      {children}
    </StyledButton>
  );
};
Button.propTypes = {
  children: PropTypes.node
};
Button.defaultProps = {
  children: null
};

export default Button;
