const tempHEMAprojects = [
  {
    value: '191038'
  },
  {
    value: '1909149'
  },
  {
    value: '1909150'
  },
  {
    value: '1909151'
  },
  {
    value: '1909152'
  },
  {
    value: '1909153'
  },
  {
    value: '1909156'
  },
  {
    value: '1909154'
  },
  {
    value: '1909155'
  },
  {
    value: '1909157'
  },
  {
    value: '1909160'
  },
  {
    value: '1909161'
  },
  {
    value: '1909162'
  },
  {
    value: '1909173'
  },
  {
    value: '1909174'
  },
  {
    value: '1909175'
  },
  {
    value: '1909176'
  },
  {
    value: '1909177'
  },
  {
    value: '1909178'
  },
  {
    value: '1909179'
  },
  {
    value: '1909182'
  },
  {
    value: '1909183'
  },
  {
    value: '1909180'
  },
  {
    value: '1909184'
  },
  {
    value: '1909185'
  },
  {
    value: '1909181'
  },
  {
    value: '1909186'
  },
  {
    value: '1909187'
  },
  {
    value: '1909188'
  },
  {
    value: '1909189'
  },
  {
    value: '1909190'
  },
  {
    value: '1909191'
  },
  {
    value: '1909192'
  },
  {
    value: '1909194'
  },
  {
    value: '1909193'
  },
  {
    value: '1909195'
  },
  {
    value: '1909163'
  },
  {
    value: '1909196'
  },
  {
    value: '1909158'
  },
  {
    value: '1909159'
  },
  {
    value: '191000'
  },
  {
    value: '191001'
  },
  {
    value: '191002'
  },
  {
    value: '191003'
  },
  {
    value: '191034'
  },
  {
    value: '191033'
  },
  {
    value: '191032'
  },
  {
    value: '191031'
  },
  {
    value: '191030'
  },
  {
    value: '191029'
  },
  {
    value: '191028'
  },
  {
    value: '191027'
  },
  {
    value: '191026'
  },
  {
    value: '191025'
  },
  {
    value: '191024'
  },
  {
    value: '191023'
  },
  {
    value: '191022'
  },
  {
    value: '191021'
  },
  {
    value: '191020'
  },
  {
    value: '191019'
  },
  {
    value: '191018'
  },
  {
    value: '191017'
  },
  {
    value: '191016'
  },
  {
    value: '191015'
  },
  {
    value: '191014'
  },
  {
    value: '191012'
  },
  {
    value: '191010'
  },
  {
    value: '191009'
  },
  {
    value: '191008'
  },
  {
    value: '191035'
  },
  {
    value: '1909205'
  },
  {
    value: '1909206'
  },
  {
    value: '1909207'
  },
  {
    value: '1909208'
  },
  {
    value: '1909211'
  },
  {
    value: '1909212'
  },
  {
    value: '1909213'
  },
  {
    value: '1909214'
  },
  {
    value: '1909215'
  },
  {
    value: '1909216'
  },
  {
    value: '1909217'
  },
  {
    value: '1909210'
  },
  {
    value: '1909209'
  },
  {
    value: '1909204'
  },
  {
    value: '1909203'
  },
  {
    value: '1909202'
  },
  {
    value: '1909201'
  },
  {
    value: '1909200'
  },
  {
    value: '1909199'
  }
];

module.exports = { tempHEMAprojects };
