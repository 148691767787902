import React from 'react';
import styled from 'styled-components';

const StyledTextDiv = styled.div`
  width: 400px;
  height: ${props => (props.height ? 0 : '500px')};
  padding: 0px;
  position: absolute;
  top: ${props => (props.height ? `${props.height / 10}px` : 'initial')};
  margin: 0 auto;
  display: flex;
  left: 37.5%;
  align-items: center;
  font-weight: 700;
`;
const FullHeightContainer = styled.div`
  min-height: 100%;
  position: relative;
  top: 50%;
  height: ${props => (props.height ? `${props.height}px` : '300px')};
`;
const LoadingContainer = styled.div`
  padding: 0px;
  position: absolute;
  top: ${props => (props.height ? `${props.height / 10}%` : '50%')};
  left: 50%;
  margin: 0 auto;
  margin-left: -60px;
  margin-top: 40px;
`;
export const Loading = props => {
  return (
    <FullHeightContainer height={props.height}>
      <LoadingContainer height={props.height}>
        <div className="lds-roller">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </LoadingContainer>
      <StyledTextDiv height={props.height}>
        {props.children ? (
          props.children
        ) : props.message ? (
          <div style={{ textAlign: 'center' }}>{props.message}</div>
        ) : null}
      </StyledTextDiv>
    </FullHeightContainer>
  );
};
