import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import ErrorBoundary from '../ErrorBoundary';
import {
  getUserRole,
  getAllowedProjects,
  ROLE_ADMIN,
  ROLE_TOPADMIN,
  ROLE_CONSULTANT
  // ROLE_HEMA_CLIENT
} from './roles';

function SecuredRoute(props) {
  const { componentProps, routes, loadedMenu } = props;
  const { authenticated, auth, history, user, token } = componentProps;

  let admin = false;
  let topAdmin = false;

  const userRole = getUserRole(auth);
  if (userRole) {
    topAdmin = userRole === ROLE_TOPADMIN;
    admin = topAdmin || userRole === ROLE_ADMIN || userRole === ROLE_CONSULTANT;
  }

  const componentRoutes = routes.map(routeObject => {
    const {
      component: Component,
      path,
      exact,
      admin: adminRoute
    } = routeObject;
    return (
      <Route
        key={path || '404'}
        exact={exact}
        path={path}
        render={rrProps => {
          const { match } = rrProps;
          const { params } = match;
          const { surveyID } = params;
          if (authenticated && adminRoute && !admin) {
            return <Redirect to="/access-denied" />;
          }
          if (surveyID) {
            const hasAccessToProjects = getAllowedProjects(auth);
            if (
              !hasAccessToProjects.some(
                project => project === '*' || project.value === surveyID
              )
            ) {
              return <Redirect to="/access-denied" />;
            }
          }
          return (
            <ErrorBoundary user={user}>
              <Component
                location={rrProps}
                authenticated={authenticated}
                auth={auth}
                token={token}
                history={history}
                user={user}
                admin={admin}
                loadedMenu={loadedMenu}
              />
            </ErrorBoundary>
          );
        }}
      />
    );
  });
  return <Switch>{componentRoutes}</Switch>;
}

export default SecuredRoute;
